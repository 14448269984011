<div class="detail-container">
    <p style="font-size: 27px; font-weight: bold; text-decoration: underline; margin-bottom: 20px; padding-left: 15px;">Borrower 1</p>
    <div class="form border">
        <div style="width: 80%; margin-left: 1%;">
            <div class="form-row">
                <div class="form-label">Borrower Name</div>
                <input type="text" class="form-input" disabled value="Mah Kok Wah">
            </div>
            <div class="form-row">
                <div class="form-label">Borrower IC Number</div>
                <input type="text" class="form-input" disabled value="921116-08-7252">
            </div>

            <div class="form-row">
                <div class="form-label">Solicitor Reference</div>
                <input type="text" class="form-input" disabled value="Sean">
            </div>

            <div class="form-row">
                <div class="form-label">LOD Date</div>
                <input type="text" class="form-input" disabled value="12/08/2020">
            </div>

            <div class="form-row">
                <div class="form-label">LOD Amount</div>
                <input type="text" class="form-input" disabled value="RM 130,000.00">
            </div>

            <div class="form-row">
                <div class="form-label">LOD Posting Date</div>
                <input type="text" class="form-input" disabled value="14/08/2020">
            </div>

            <div class="form-row">
                <div class="form-label">LOT / LOR Date</div>
                <input type="text" class="form-input" disabled value="14/08/2023">
            </div>

            <div class="form-row">
                <div class="form-label">LOT / LOR Amount</div>
                <input type="text" class="form-input" disabled value="RM 120,000.00">
            </div>

            <div class="form-row">
                <div class="form-label">LOR / LOT Posting Date</div>
                <input type="text" class="form-input" disabled value="14/08/2023">
            </div>
        </div>
    </div>
    <div style="display: flex; padding-top: 20px">
        <div style="width:50%; font-size: 17px"><i>(+) Add Borrower</i></div>
        <div class="btn-cont" style="width:50%">
            <button routerLink="/" class="btn-orange">Back</button>
        </div>
    </div>
</div>
