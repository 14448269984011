<div class="modal" id="init">
    <div class="modal-window">
        <div class="modal-header">
            <h1>Initiate Co-Browse</h1>
            <div class="close" (click)="closeModal()">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="modal-body">
            <div class="init-form-row">
                <div class="init-form-label">Name</div>
                <input type="text" class="init-form-input">
            </div>
            <div class="init-form-row">
                <div class="init-form-label">Mobile Number</div>
                <select style="height: 36px; width: 150px; padding-left: 20px; top: -3px; position: relative; border-radius: 10px; border: none; outline: none;">
                  <option selected>Malaysia +60</option>
                  <option>Singapore +65</option>
                </select>
                <!-- <input type="dropdown" class="init-form-input" style="width:40%" placeholder="Malaysia +60" disabled> -->
                <input id="phoneNo" type="tel" class="init-form-input" [(ngModel)]="phoneNo" style="margin-left: 10px; width:calc(60% - 10px)">
            </div>
            <div class="init-form-row">
                <div class="init-form-label">Co-Browse Document</div>
                <input type=file id="upload">
            </div>
            <div class="init-form-row">
                <button class="init-form-btn" (click)="initiate()">Initiate</button>
            </div>
        </div>
    </div>
</div>
