<div class="listing-container">
    <div class="listing-form">
        <div class="form" style="width: 50%">
                <div class="form-row">
                    <div class="form-label">Defendant Name</div>
                    <input type="text" class="form-input" [(ngModel)]="model.name" />
                </div>

                <div class="form-row">
                    <div class="form-label">Loan Number</div>
                    <input type="text" class="form-input" [(ngModel)]="model.loanNo" />
                </div>

                <div class="form-row">
                    <div class="form-label">Status</div>
                    <input type="text" class="form-input" [(ngModel)]="model.status" />
                </div>

                <div class="form-btn-cont">
                    <button class="form-btn" (click)="clear()">Clear</button>
                    <button class="form-btn" (click)="search()">Search</button>
                </div>
        </div>

        <div class="form" style="width: 50%">
            <div class="btn-cont">
                <button class="btn-orange">Create New</button>
            </div>
        </div>
    </div>

    <div class="listing-result">
        <table class="table">
            <tr>
                <th>No</th>
                <th>Loan Number</th>
                <th>Defendant Name</th>
                <th>IC Number</th>
                <th>Status</th>
                <th>Co-Browse Signing</th>
            </tr>
            <tr *ngFor="let item of defendants, let i = index">
                <td>{{i + 1}}</td>
                <td><a routerLink="/detail">{{item.loanNo}}</a></td>
                <td>{{item.name}}</td>
                <td>{{item.icNo}}</td>
                <td>{{item.status}}</td>
                <td (click)="openModal()" style="text-align: center;"><img src="../../assets/images/open_file.png" width="25px" height="25px" /></td>
            </tr>
        </table>

    </div>
</div>

<app-init></app-init>
