import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InitRequestModel, InitResponseModel } from '../model/init-req-resp-model';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {

  resp: any;
  phoneNo: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    document.documentElement.classList.remove('body-no-scroll');
    let modal: any = document.getElementById('init');
    modal.classList.remove('active');
  }

  initiate() {
    let url = 'https://uat.truesight.fermiondigital.com/app/api/initiate-video-call';
    let body = new InitRequestModel();
    body = {
      "country": "my",
      "caseID": "1234",
      "phoneNo": "60" + this.phoneNo,
      "timeUsed": "",
      "timeLimit": "",
      "storeVideo": "true",
      "companyID": "",
      "SMSLangPref": "EN",
      "offerButton": "false",
      "offerAmount": null,
      "reOfferOption": "false",
      "manualOfferButton": "false",
      "isAccidentLocation": "false"
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Max-Age': '3600',
    });

    this.http.post(url, body, { headers }).subscribe(response => {
      this.resp = response;
      let videoUrl = this.resp.url;
      this.goToLink(videoUrl);
    });
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
