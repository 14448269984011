export class InitRequestModel {
    country: string;
    caseID: string
    phoneNo: string;
    timeUsed: string;
    timeLimit: string;
    storeVideo: string;
    companyID: string;
    SMSLangPref: string;
    offerButton: string;
    offerAmount: string;
    reOfferOption: string;
    manualOfferButton: string;
    isAccidentLocation: string;
}

export class InitResponseModel {
    callOD: string;
    url: string
    message: string;
    video: string;
    status: string;
}