<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<nav class="header">
  <div class="header-logo"><h1>SilverBank</h1></div>
  <div class="header-nav">
    <span class="nav-item">
      <i class="nav-icon fa fa-home"></i>
      <a class="nav-link" href="#">User Access</a>
    </span>
    <span class="nav-item nav-sub">
      <i class="nav-icon fa fa-cogs"></i>
      <a class="nav-link" href="#">Maintenance</a>
      <i class="nav-icon fa fa-caret-square-o-down" style="font-size: 12px; padding-top: 6px; opacity: 0.4"></i>
      <span class="nav-sub-items">
        <a class="nav-sub-link" href="#">Initiation</a>
        <a class="nav-sub-link" href="#">Summon</a>
        <a class="nav-sub-link" href="#">Writ of Seizure and Sale</a>
        <a class="nav-sub-link" href="#">Judgement Debtor Summmons</a>
        <a class="nav-sub-link" href="#">Bankruptcy</a>
        <a class="nav-sub-link" href="#">Foreclosure (LO, HC & LACA)</a>
        <a class="nav-sub-link" href="#">Legal Support</a>
        <a class="nav-sub-link" href="#">OA Liaison</a>
      </span>
    </span>
    <span class="nav-item">
      <i class="nav-icon fa fa-lock"></i>
      <a class="nav-link" href="#">Change Password</a>
    </span>
    <span class="nav-item">
      <i class="nav-icon fa fa-sign-out"></i>
      <a class="nav-link" href="#">Logout</a>
    </span>
  </div>
</nav>
<router-outlet></router-outlet>
