import { Component, OnInit } from '@angular/core';
import { DefendantModel } from '../model/defendant-model';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  defendants: Array<DefendantModel>;
  isSearch: boolean;
  model: DefendantModel = new DefendantModel();

  constructor() { }

  ngOnInit(): void {
    this.isSearch = false;
    this.clear();

    this.defendants = [
      {
        name: "Mah Kok Wah",
        loanNo: "8881026452",
        icNo: "921116-08-7252",
        status: "Pending"
      },
      {
        name: "Richard",
        loanNo: "8896256252",
        icNo: "880901-02-3122",
        status: "Completed"
      },
      {
        name: "Amy",
        loanNo: "8872531613",
        icNo: "600728-02-6634",
        status: "Submitted for Processing"
      }
    ]
  }

  search() {
    this.isSearch = true;
  }

  clear() {
    this.model.name = "";
    this.model.loanNo = "";
    this.model.status = "";

  }
  openModal(): void {
    document.documentElement.classList.add('body-no-scroll');

    let modal: any = document.getElementById('init');
    modal.classList.add('active');
    modal.scrollTo(0, 0);
  }
}
